<template>
  <v-card :height="210">
    <v-card-title class="mb-0 pb-1">
      Transaction details
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-container class="mt-0">
        <v-row>
          <v-col
            sm="4"
            md="3"
            cols="12"
            class="mb-4"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Payment Reference
              </h4>
              <h4 class="font-weight-medium">
                {{ transaction.TransID }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Amount Paid
              </h4>
              <h4 class="font-weight-medium">
                Ksh {{ transaction.TransAmount | formatCurrency }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Name
              </h4>
              <h4 class="font-weight-medium">
                {{ transaction.FirstName }} {{ transaction.MiddleName }} {{ transaction.LastName }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Phone number
              </h4>
              <h4 class="font-weight-medium">
                {{ transaction.MSISDN }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Transaction Date
              </h4>
              <h4 class="font-weight-medium">
                {{ transaction.created_at | formatDate }}
              </h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import DetailsTextShimmer from '@/components/partials/shimmers/DetailsTextShimmer.vue'

export default {
  components: {
    'details-text-shimmer': DetailsTextShimmer,
  },
  props: {
    transaction: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style>

</style>
