<template>
  <div class="transactions">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
    </v-row>
    <transaction-details-card
      :transaction="details.transaction"
      :is-loading="isLoading"
    />
    <br>
    <user-details-card
      :user="details.user"
      :is-loading="isLoading"
    />
  </div>
</template>
<script>
import axios from 'axios'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import UserDetailsCard from '@/components/details-card/UserDetailsCard.vue'
import TransactionDetailsCard from '@/components/details-card/TransactionDetailsCard.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    'user-details-card': UserDetailsCard,
    'transaction-details-card': TransactionDetailsCard,
  },
  data() {
    return {
      search: '',
      details: { user: {}, transaction: {} },
      isLoading: true,
      transactionId: this.$route.params.id,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Transactions',
          disabled: false,
          to: { name: 'transactions' },
        },
      ],
    }
  },
  mounted() {
    this.getTransaction()
    if (this.transactionId) {
      this.breadcrumbs.push(
        {
          text: this.transactionId,
          disabled: true,
          to: { name: 'transaction-details' },
        },
      )
    }
  },
  methods: {
    getTransaction() {
      this.isLoading = true
      axios
        .get(`transactions/${this.transactionId}`)
        .then(response => {
          this.details = response.data
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>
